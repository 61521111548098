{
  "host": "http://localhost:1234",
  "redirectUrl": "http://localhost:1234/login/callback",
  "authUrl": "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7",
  "rosHost": "https://retailoperations.retailconfigui-test.nikecloud.com/retailoperations",
  "storeviewsHost": "https://storeviews-regional-us-east-1.risedomain-test.nikecloud.com",
  "scsRegionOptions": [
    { "value": "use1", "label": "US East" }
  ],
  "adminServiceEndpoint": "https://esl-stores-admin-service-us-east-1.esl-test.nikecloud.com/store/proxy_esl_stores/v1"
}
