/* eslint-disable no-console */
/* eslint-disable fp/no-let */
/* eslint-disable fp/no-mutation */
import React, { useState } from 'react';
import {
  Form,
  FormGroup,
  Input,
  Button
} from 'reactstrap';

import activeConfig from '../../config';
import useFetch from '../../hooks/useFetch';

function AddStore() {
  const [addStoreState, setAddStore] = useState({
    divisions: '',
    isEnabled: true,
    locale: '',
    storeId: '',
    vendor: '',
    vendorStoreId: ''
  });

  const { handleFetch } = useFetch(activeConfig.adminServiceEndpoint, {
    method: 'POST',
    onResponse: () => {
      window.location.assign('home');
    }
  });

  const change = (inputField) => (e) => {
    const { value } = e.target;
    setAddStore((state) => ({ ...state, [inputField]: value }));
  };

  const handleCheckBox = () => {
    setAddStore((state) => ({ ...state, isEnabled: !state.isEnabled }));
  };

  const submit = (e) => {
    e.preventDefault();
    handleFetch(JSON.stringify({
      divisions: addStoreState.divisions,
      enabled: addStoreState.isEnabled,
      locale: addStoreState.locale,
      storeId: addStoreState.storeId,
      vendor: addStoreState.vendor,
      vendorStoreId: addStoreState.vendorStoreId
    }));
  };

  return (
    <Form className="ncss-col-sm-6 ta-sm-c p6-sm mb12-sm" onSubmit={submit}>
      <FormGroup>
        <h1 className="required-field">Store ID</h1>
        <Input required placeholder="Enter Store ID" type="text" value={addStoreState.storeId} onChange={change('storeId')}> </Input>

        <h1>Divisions</h1>
        <Input placeholder="Enter Divisions" type="text" value={addStoreState.divisions} onChange={change('divisions')}> </Input>

        <h1>Enabled</h1>
        <br />

        <label className="toggle">
          <input checked={addStoreState.isEnabled} className="toggle-checkbox" type="checkbox" onChange={handleCheckBox} />
          <div className="toggle-switch" />
        </label>

        <br />
        <br />

        <h1>Vendor</h1>
        <Input placeholder="Enter Vendor" type="text" value={addStoreState.vendor} onChange={change('vendor')}> </Input>

        <h1>Vendor Store ID</h1>
        <Input placeholder="Enter Vendor Store ID" type="text" value={addStoreState.vendorStoreId} onChange={change('vendorStoreId')}> </Input>

        <h1>Locale</h1>
        <Input placeholder="Enter locale" type="text" value={addStoreState.locale} onChange={change('locale')}> </Input>
      </FormGroup>

      <Button type="submit">Submit</Button>
    </Form>

  );
}

export default AddStore;
