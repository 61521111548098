import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './components/service-stores-ui/OktaAuth/index.css';

import App from './App';
import config from './components/service-stores-ui/OktaAuth/config';

ReactDOM.render(
  <Router basename={config.app.basename}>
    <App />
  </Router>,
  document.getElementById('root'),
);
