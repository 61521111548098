import activeConfig from '../../../config';

const CLIENT_ID = 'nike.eslstoremanagement.webapp';
const ISSUER = activeConfig.authUrl;
const BASENAME = '';

export default {
  app: {
    basename: BASENAME,
  },
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    pkce: true,
    redirectUri: activeConfig.redirectUrl,
    scopes: ['openid', 'profile', 'email']
  },
  resourceServer: {
    messagesUrl: 'http://localhost:1234/api/messages',
  },
};
