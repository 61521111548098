import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
    } else {
      oktaAuth.getUser().then(() => null).catch(() => {
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const login = async () => {
    oktaAuth.signInWithRedirect({ originalUri: '/home' });
  };

  if (!authState) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <header className="bg-black ta-sm-c va-sm-m pt10-sm pb12-sm" id="main">
      <h1 aria-label="retail operations header" className="display-2 text-color-accent" id="heading">ESL Service Stores</h1>

      <button className="btn btn-primary" id="addButton" type="submit" onClick={login}>
        <i className="fa fa fa-plus-square">Login</i>
      </button>

    </header>
  );
};
export default Home;
