/* eslint-disable no-console */
import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';

import activeConfig from '../../config';
import useFetch from '../../hooks/useFetch';

function StoreList() {
  const { data: newStores, loading } = useFetch(activeConfig.adminServiceEndpoint);

  const stores = newStores;

  if (loading) {
    return (
      <>
        <div className="loader" />
      </>
    );
  } else {
    return (
      <>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
        <div className="StoreList">
          <table>
            <tr>
              <th>Timezone</th>
              <th>StoreID</th>
              <th>Divisions</th>
              <th>Enabled</th>
              <th>Marketplace</th>
              <th>Name</th>
              <th>SIMRegion</th>
              <th>Store Number</th>
              <th>Vendor</th>
              <th>Vendor Store ID</th>
              <th>locale</th>
              <th>Actions</th>
            </tr>
            {stores != null && stores.map((item) => (
              <tr>
                <td>{item.timezone}</td>
                <td>{item.storeId}</td>
                <td>{item.divisions}</td>
                <td>
                  {(item.enabled === true
                    ? (<input checked disabled className="toggle-checkbox" type="checkbox" />)
                    : (<input disabled className="toggle-checkbox" type="checkbox" />)
                  )}
                  <div className="toggle-switch" />

                </td>
                <td>{item.marketplace}</td>
                <td>{item.name}</td>
                <td>{item.simRegion}</td>
                <td>{item.storeNumber}</td>
                <td>{item.vendor}</td>
                <td>{item.vendorStoreId}</td>
                <td>{item.locale}</td>

                <td>
                  <div className="actions">
                    <div className="updateBtn">
                      <Link className="btn btn-primary" to={`/update?storeId=${item.storeId}`}>
                        <i className="fa fa-edit" />
                      </Link>
                    </div>

                  </div>
                </td>

              </tr>

            ))}

          </table>
        </div>
      </>

    );
  }
}

export default StoreList;
