import React from 'react';

import StoreList from './StoreList';

import './index.css';

const STOREUI = () => (
  <>
    <header className="bg-black ta-sm-c va-sm-m pt10-sm pb12-sm" id="main">
      <h1 aria-label="retail operations header" className="display-2 text-color-accent" id="heading">ESL Service Stores</h1>

      <a href="/add">
        <button className="btn btn-primary" id="addButton" type="submit">
          <i className="fa fa fa-plus-square"> Add Store </i>
        </button>
      </a>

    </header>

    <StoreList />
  </>
);

export default STOREUI;
