import React, { useState } from 'react';
import {
  Form,
  FormGroup,
  Input,
  Button
} from 'reactstrap';

import activeConfig from '../../config';
import useFetch from '../../hooks/useFetch';

function UpdateStore() {
  const storeId = new URLSearchParams(window.location.search).get('storeId');

  const [storeState, setStoreState] = useState({
    divisions: '',
    enabled: false,
    locale: '',
    marketplace: '',
    name: '',
    simRegion: '',
    storeId: '',
    storeNumber: '',
    timezone: '',
    vendor: '',
    vendorStoreId: '',
  });

  const { loading } = useFetch(`${activeConfig.adminServiceEndpoint}/${storeId}`, {
    onResponse: (res) => {
      setStoreState({ ...res, divisions: res.divisions.join(',') });
    }
  });

  const { loading: storeUpdateLoading, handleFetch } = useFetch(`${activeConfig.adminServiceEndpoint}/${storeId}`, {
    method: 'PUT',
    onResponse: () => {
      window.location.assign('home');
    }
  });

  const change = (inputField) => (e) => {
    const { value } = e.target;
    setStoreState((state) => ({ ...state, [inputField]: value }));
  };

  const handleCheckBox = () => {
    setStoreState((state) => ({ ...state, enabled: !state.enabled }));
  };

  const submit = (e) => {
    e.preventDefault();
    handleFetch(JSON.stringify({
      ...storeState,
      divisions: storeState.divisions.split(',')
    }));
  };

  if (loading || storeUpdateLoading) {
    return (
      <>
        <div className="loader" />
      </>
    );
  } else {
    return (
      <Form className="ncss-col-sm-6 ta-sm-c p6-sm mb12-sm" onSubmit={submit}>
        <FormGroup>
          <h1>Timezone</h1>
          <Input disabled type="text" value={storeState.timezone} onChange={change('timezone')}> </Input>

          <h1>Store ID</h1>
          <Input disabled type="text" value={storeState.storeId} onChange={change('storeId')}> </Input>

          <h1>Divisions</h1>
          <Input type="text" value={storeState.divisions} onChange={change('divisions')}> </Input>

          <h1>Enabled</h1>
          <br />

          <label className="toggle">
            <input checked={storeState.enabled} className="toggle-checkbox" type="checkbox" onChange={handleCheckBox} />;
            <div className="toggle-switch" />
          </label>

          <br />
          <br />

          <h1>Marketplace</h1>
          <Input disabled type="text" value={storeState.marketplace} onChange={change('marketplace')}> </Input>

          <h1>Name</h1>
          <Input disabled type="text" value={storeState.name} onChange={change('name')}> </Input>

          <h1>SIM Region</h1>
          <Input disabled type="text" value={storeState.simRegion} onChange={change('simRegion')}> </Input>

          <h1>Store Number</h1>
          <Input disabled type="text" value={storeState.storeNumber} onChange={change('storeNumber')}> </Input>

          <h1>Vendor</h1>
          <Input type="text" value={storeState.vendor} onChange={change('vendor')}> </Input>

          <h1>Vendor Store ID</h1>
          <Input type="text" value={storeState.vendorStoreId} onChange={change('vendorStoreId')}> </Input>

          <h1>locale</h1>
          <Input type="text" value={storeState.locale} onChange={change('locale')}> </Input>

          <br />
          <Button type="submit">Submit</Button>
        </FormGroup>
      </Form>
    );
  }
}

export default UpdateStore;
