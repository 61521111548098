import 'bootstrap/dist/css/bootstrap.css';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import React from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';

import STOREUI from './components/service-stores-ui';
import AddStore from './components/service-stores-ui/AddStore';
import Authorize from './components/service-stores-ui/OktaAuth/Authorize';
import config from './components/service-stores-ui/OktaAuth/config';
import UpdateStore from './components/service-stores-ui/UpdateStore';

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const history = useHistory(); // example from react-router

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    }
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={customAuthHandler}>
      <Switch>
        <Route exact component={Authorize} path="/" />
        <SecureRoute exact component={STOREUI} path="/home" />
        <Route component={LoginCallback} path="/login/callback" />
        <SecureRoute exact component={AddStore} path="/add" />
        <SecureRoute exact component={UpdateStore} path="/update" />
      </Switch>
    </Security>
  );
};

export default App;
