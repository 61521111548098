import { useOktaAuth } from '@okta/okta-react';
import {
  useState, useEffect, useCallback
} from 'react';

const useFetch = (url, options = { headers: {}, method: 'GET', onResponse: () => {} }) => {
  const { authState } = useOktaAuth();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseError, setError] = useState(null);

  const handleFetch = useCallback(async (body) => {
    try {
      setLoading(true);
      const response = await fetch(url, {
        ...options,
        body: body || options.body,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      const jsonData = await response.json();
      setData(jsonData);
      setLoading(false);
      options.onResponse(jsonData);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, authState?.accessToken?.accessToken]);

  useEffect(() => {
    if (!options.method || options.method.toUpperCase() === 'GET') {
      handleFetch();
    }
  }, [url, options.method, handleFetch]);

  return {
    data, error: responseError, handleFetch, loading
  };
};

export default useFetch;
